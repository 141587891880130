import { kebabCase } from 'lodash'
import { CardRow } from './types'

function normalizeList(list: CardRow[]) {
  const result = list.map((row) => ({
    ...row,
    slug: kebabCase(row.name.replace(/\/\/.*$/, '')),
  }))

  for (let index = list.length - 1; index >= 1; index--) {
    for (let innerIndex = 0; innerIndex < index; innerIndex++) {
      if (result[index].slug === result[innerIndex].slug) {
        result[innerIndex].count += result[index].count
        result.splice(index, 1)
        break
      }
    }
  }

  return result
}

function difference(
  left: (CardRow & { slug: string })[],
  right: (CardRow & { slug: string })[],
) {
  return left.reduce(
    (result, row) => {
      const rightRow = right.find((r) => r.slug === row.slug)

      if (rightRow != null) {
        if (row.count == rightRow.count) {
          result.shared.push(row)
        } else if (row.count <= rightRow.count) {
          result.shared.push({ ...row, partiallyShared: true })
        } else if (row.count > rightRow.count) {
          result.shared.push({ ...rightRow, partiallyShared: true })
          result.unique.push({
            ...row,
            count: row.count - rightRow.count,
            partiallyShared: true,
          })
        }
      } else {
        result.unique.push(row)
      }

      return result
    },
    { unique: [], shared: [] } as {
      unique: CardRow[]
      shared: CardRow[]
    },
  )
}

export function compareLists(left: CardRow[], right: CardRow[]) {
  const normalLeft = normalizeList(left)
  const normalRight = normalizeList(right)

  const d = difference(normalLeft, normalRight)

  return {
    left: d.unique,
    shared: d.shared,
    right: difference(normalRight, normalLeft).unique,
  }
}
