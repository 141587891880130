import * as styles from './ListDiffer.module.scss'

import React, { useCallback, useState } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

import * as controls from 'components/controls'
import Indicator from 'components/loading/Indicator'

import { AccentColor } from 'utils/accent-color/AccentColorContext'
import { copyToClipboard } from 'utils/copyToClipboard'
import { usePersistentState } from 'utils/usePersistentState'
import { downloadCSV } from 'utils/downloadCSV'

import { loadCards } from './data/loadCards'
import { formatCSV } from './data/formatCSV'
import { CompareResult } from './data/types'
import { defaultOptions } from './data/options'
import { useInputState } from './data/inputState'
import { compareLists } from './data/compareLists'

import { Input } from './input/Input'
import { MetaControls } from './MetaControls'
import { Footer } from './Footer'
import { ResultColumn } from './ResultColumn'
import { Options } from './Options'

const leftColor = '#1EA261'
const rightColor = '#483FD5'

export const ListDiffer: React.FC = () => {
  const {
    leftInputMode,
    setLeftInputMode,
    leftInputContent,
    setLeftInputContent,
    rightInputMode,
    setRightInputMode,
    rightInputContent,
    setRightInputContent,
    resetInputState,
  } = useInputState()

  const [showOptions, setShowOptions] = useState(false)
  const [options, setOptions] = usePersistentState(
    'list-differ-options',
    defaultOptions,
  )

  const [result, setResult] = useState<CompareResult | null>(null)
  const [leftError, setLeftError] = useState<string | null>(null)
  const [rightError, setRightError] = useState<string | null>(null)

  const [loading, setLoading] = useState(false)

  const load = useCallback(() => {
    if (
      leftInputContent.length > 0 &&
      rightInputContent.length > 0 &&
      !loading
    ) {
      setLeftError(null)
      setRightError(null)
      setLoading(true)

      loadCards(leftInputMode, leftInputContent).then((leftList) => {
        loadCards(rightInputMode, rightInputContent).then((rightList) => {
          setLoading(false)

          if ('error' in leftList && leftList.error != null) {
            setLeftError(leftList.error)
          }

          if ('error' in rightList) {
            setRightError(rightList.error)
          }

          const { left, shared, right } = compareLists(
            leftList.cards,
            rightList.cards,
          )

          setResult({
            left: {
              source: leftInputMode,
              title: leftList.title,
              cards: left,
            },
            shared: {
              source: 'intersection',
              cards: shared,
            },
            right: {
              source: rightInputMode,
              title: rightList.title,
              cards: right,
            },
          })
        })
      })
    }
  }, [
    leftInputContent,
    leftInputMode,
    loading,
    rightInputContent,
    rightInputMode,
  ])

  const copyResultCSV = useCallback(() => {
    if (result != null) {
      copyToClipboard(formatCSV(result, options))
    }
  }, [result, options])

  const downloadResultCSV = useCallback(() => {
    if (result != null) {
      downloadCSV(formatCSV(result, options), 'list-comparison')
    }
  }, [result, options])

  const reset = useCallback(() => {
    setResult(null)
    resetInputState()
    setLeftError(null)
    setRightError(null)
  }, [resetInputState])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          MTG List Differ by <Link to="/">Lucky Paper</Link>
        </h1>
        <div className={styles.metaControls}>
          <MetaControls reset={reset} />
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.input}>
          <AccentColor color={leftColor}>
            <Input
              inputMode={leftInputMode}
              setInputMode={setLeftInputMode}
              inputContent={leftInputContent}
              setInputContent={setLeftInputContent}
              load={load}
              error={leftError}
            />
          </AccentColor>

          <AccentColor color={rightColor}>
            <Input
              inputMode={rightInputMode}
              setInputMode={setRightInputMode}
              inputContent={rightInputContent}
              setInputContent={setRightInputContent}
              load={load}
              error={rightError}
            />
          </AccentColor>
        </div>

        <div className={styles.actions}>
          <controls.ControlGroup>
            <controls.SmallButton onClick={() => setShowOptions(true)}>
              Options
            </controls.SmallButton>
          </controls.ControlGroup>

          <controls.Button
            onClick={load}
            disabled={
              loading ||
              leftInputContent.length === 0 ||
              rightInputContent.length === 0
            }
          >
            Compare
            <span className={styles.submitIcon}>
              {loading ? <Indicator size="0.8em" inline /> : <>&darr;</>}
            </span>
          </controls.Button>

          <controls.ControlGroup>
            <controls.SmallButton
              onClick={downloadResultCSV}
              disabled={result == null}
            >
              Download CSV
            </controls.SmallButton>

            <controls.CopyButton
              onClick={copyResultCSV}
              disabled={result == null}
            >
              Copy CSV
            </controls.CopyButton>
          </controls.ControlGroup>
        </div>

        {result != null && (
          <div className={styles.result}>
            <div
              className={classNames(styles.columnHeading, {
                [styles.monoSpace]: result.left.source === 'search',
              })}
            >
              {result.left.title}
            </div>

            <AccentColor color={leftColor}>
              <ResultColumn
                heading="Left Only"
                colors={[leftColor]}
                list={result.left}
                options={options}
              />
            </AccentColor>

            <div
              className={classNames(styles.columnHeading, styles.shared)}
            ></div>
            <ResultColumn
              heading="Both"
              colors={[leftColor, rightColor]}
              list={result.shared}
              options={options}
            />

            <div
              className={classNames(
                classNames(styles.columnHeading, {
                  [styles.monoSpace]: result.right.source === 'search',
                }),
                styles.right,
              )}
            >
              {result.right.title}
            </div>
            <AccentColor color={rightColor}>
              <ResultColumn
                heading="Right Only"
                colors={[rightColor]}
                list={result.right}
                options={options}
              />
            </AccentColor>
          </div>
        )}
      </div>

      <AccentColor color="#404142">
        <controls.ModalWithHeading
          presented={showOptions}
          dismiss={() => setShowOptions(false)}
          title="Formatting Options"
        >
          <Options options={options} setOptions={setOptions} />
        </controls.ModalWithHeading>
      </AccentColor>

      <Footer />
    </div>
  )
}
