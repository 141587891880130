import * as styles from './Input.module.scss'

import React, { useCallback } from 'react'
import { Link } from 'gatsby'

import * as controls from 'components/controls'

import { InputMode } from '../data/types'

import { DropZone } from './DropZone'

interface Props {
  inputMode: InputMode
  setInputMode(value: InputMode): void
  inputContent: string
  setInputContent(value: string): void
  load(): void
  error: string | null
}

export const Input: React.FC<Props> = (props) => {
  const {
    inputMode,
    setInputMode,
    inputContent,
    setInputContent,
    load,
    error,
  } = props

  const onInputKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        load()
      }
    },
    [load],
  )

  return (
    <div className={styles.container}>
      <controls.SegmentedControl
        segments={{ list: 'List', search: 'Search', link: 'Link' }}
        value={inputMode}
        onChange={(event) => {
          setInputMode(event.currentTarget.value as any)
        }}
      />

      {inputMode === 'list' && (
        <DropZone setInput={setInputContent}>
          <textarea
            value={inputContent}
            onChange={(event) => setInputContent(event.currentTarget.value)}
            className={styles.inputTextArea}
            spellCheck={false}
          />
          <div className={styles.hint}>Paste or drop a list of card names.</div>
        </DropZone>
      )}

      {inputMode === 'search' && (
        <div>
          <input
            type="text"
            value={inputContent}
            onChange={(event) => setInputContent(event.currentTarget.value)}
            onKeyPress={onInputKeyPress}
            className={styles.inputField}
            spellCheck={false}
          />
          <div className={styles.hint}>
            Enter a search using{' '}
            <Link
              to="/articles/searching-with-scryfall-magic-at-your-fingertips/"
              tabIndex={-1}
            >
              Scryfall&rsquo;s query syntax
            </Link>
            .
          </div>
        </div>
      )}

      {inputMode === 'link' && (
        <div>
          <input
            type="text"
            value={inputContent}
            onChange={(event) => setInputContent(event.currentTarget.value)}
            onKeyPress={onInputKeyPress}
            className={styles.inputField}
            spellCheck={false}
          />
          <div className={styles.hint}>
            Enter a link to a card list page. Supports{' '}
            <a href="http://scryfall.com/decks">Scryfall Deckbuilder</a> and{' '}
            <a href="https://cubecobra.com">Cube Cobra</a>.
          </div>
        </div>
      )}

      {error && <div className={styles.error}>{error}</div>}
    </div>
  )
}
