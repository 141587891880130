import * as d3 from 'd3'
import { range } from 'lodash'

import { CompareResult } from './types'
import { Options } from './options'
import { formatList } from './formatList'

export function formatCSV(results: CompareResult, options: Options) {
  const { left, shared, right } = results

  const formattedLists = {
    left: formatList(left, options),
    shared: formatList(shared, options),
    right: formatList(right, options),
  }

  const rowsCount = Math.max(
    formattedLists.left.length,
    formattedLists.shared.length,
    formattedLists.right.length,
  )

  return d3.csvFormatRows([
    ['Left', 'Shared', 'Right'],
    ...range(0, rowsCount).map((index) => {
      return [
        formattedLists.left[index] ?? '',
        formattedLists.shared[index] ?? '',
        formattedLists.right[index] ?? '',
      ]
    }),
  ])
}
