import { compact } from 'lodash'

import { toInt } from 'utils/typeConversions'

const cardPattern = /^ *(?<count>\d+)?x? *(?<card>.+)$/

export const parseTextInput = (text: string) => {
  return compact(
    text.split('\n').map((row) => {
      const result = row.trim().match(cardPattern)

      if (!result?.groups?.card) {
        return null
      }

      return {
        name: result?.groups?.card,
        count: toInt(result?.groups?.count) ?? 1,
      }
    }),
  )
}
