import { compact } from 'lodash'

import * as scryfall from 'utils/scryfall'
import { fetchCubeCards } from 'utils/cube-cobra'

import { CardRow, InputMode } from '../data/types'
import { sourceForURL } from './sourceForURL'
import { parseTextInput } from './parseTextInput'

export async function loadCards(
  inputMode: InputMode,
  input: string,
): Promise<
  | { title: string | null; cards: CardRow[] }
  | { error: string; cards: CardRow[]; title: null }
> {
  if (inputMode === 'search') {
    if (input.length > 2) {
      const result = await scryfall.search(input, { maxPages: 20 })

      return {
        title: input,
        cards: result.map((card) => ({ name: card.name, count: 1 })),
      }
    }
  } else if (inputMode === 'link') {
    const source = sourceForURL(input)

    if (source?.source === 'cube-cobra') {
      const result = await fetchCubeCards(source.id)

      return result?.cards != null
        ? {
            title: result.cube.name,
            cards: result.cards.map((card) => ({ name: card.name, count: 1 })),
          }
        : { title: null, cards: [] }
    } else if (source?.source === 'scryfall') {
      const result = await scryfall.fetchDeck(source.id)

      return result?.entries != null
        ? {
            title: result.name,
            cards: compact(
              Object.values(result.entries)
                .flatMap((entries) => entries)
                .map((entry) => {
                  if (entry.card_digest == null) {
                    return null
                  }

                  return {
                    name: entry.card_digest?.name,
                    count: entry.count,
                  }
                }),
            ),
          }
        : { title: null, cards: [] }
    }

    return {
      error: 'Source Not Recognized',
      cards: [],
      title: null,
    }
  }

  return {
    title: null,
    cards: parseTextInput(input),
  }
}
