import * as styles from './Help.module.scss'

import React from 'react'
import { Link } from 'gatsby'

export const Help: React.FC = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>MTG List Differ by Lucky Paper</h2>

      <p>Compare two lists of Magic cards.</p>

      <p>
        Input a plain text lists of cards, load lists from{' '}
        <a href="https://cubecobra.com" target="_blank" rel="noreferrer">
          Cube Cobra
        </a>{' '}
        or{' '}
        <a href="https://scryfall.com" target="_blank" rel="noreferrer">
          Scryfall
        </a>
        , or get lists of cards from a{' '}
        <Link to="/articles/searching-with-scryfall-magic-at-your-fingertips/">
          Scryfall search
        </Link>
        . Get the unique cards from each list and the shared cards.
      </p>

      <p>
        This might be useful if you want to see what&rsquo;s similar or
        different about two Cubes or decks, figure out what swaps you need to
        make between different versions of a list, or generate a buy/trade list.
      </p>

      <hr className={styles.rule} />

      <h2 className={styles.heading}>
        Questions, issues, or feature requests?
      </h2>

      <p>
        If you have any issues, questions, or a use case this tool doesn&rsquo;t{' '}
        <em>quite</em>
        cover, don&rsquo;t hesitate to reach out to me on Bluesky{' '}
        <a
          href="https://bsky.app/profile/anthony.luckypaper.co"
          target="_blank"
          rel="noreferrer"
        >
          @anthony.luckypaper.co
        </a>{' '}
        , find me on Discord, or <Link to="/contact/">contact Lucky Paper</Link>{' '}
        by email.
      </p>

      <hr className={styles.rule} />

      <h2 className={styles.heading}>What is Lucky Paper?</h2>

      <p>
        A few friends making things about Magic including{' '}
        <Link to="/articles/">articles</Link>,{' '}
        <Link to="/resources/cube-map/">data visualizations</Link> a{' '}
        <Link to="/podcast/">podcast</Link>, and{' '}
        <Link to="/resources/">tools like this</Link>. We focus on{' '}
        <Link to="/what-is-a-cube/">Cube</Link> and other sandbox formats.
      </p>
    </div>
  )
}
