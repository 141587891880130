// extracted by mini-css-extract-plugin
export var actions = "ListDiffer-module--actions--7acd6";
export var columnHeading = "ListDiffer-module--columnHeading--030a9";
export var container = "ListDiffer-module--container--7bf6b";
export var content = "ListDiffer-module--content--e9e55";
export var header = "ListDiffer-module--header--a4c0b";
export var input = "ListDiffer-module--input--09b33";
export var metaControls = "ListDiffer-module--metaControls--e3a10";
export var monoSpace = "ListDiffer-module--monoSpace--bc4b4";
export var result = "ListDiffer-module--result--e627f";
export var right = "ListDiffer-module--right--4c997";
export var shared = "ListDiffer-module--shared--cdcb1";
export var submit = "ListDiffer-module--submit--312a6";
export var submitIcon = "ListDiffer-module--submitIcon--b4727";
export var title = "ListDiffer-module--title--8a627";