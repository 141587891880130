import * as styles from './DropZone.module.scss'

import React, { useCallback, useState } from 'react'

interface Props {
  children: React.ReactNode
  setInput(value: string): void
}

export const DropZone: React.FC<Props> = (props) => {
  const { children, setInput } = props

  const [draggedOver, setDraggedOver] = useState(false)

  const onDragEnter = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDraggedOver(true)
  }, [])

  const onDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDraggedOver(false)
  }, [])

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setDraggedOver(false)

      const reader = new FileReader()
      reader.onload = (event) => {
        if (typeof event.target?.result === 'string') {
          setInput(event.target.result)
        }
      }
      reader.readAsText(event.dataTransfer.files[0])
    },
    [setInput],
  )

  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }, [])

  return (
    <div
      className={styles.container}
      id="csv_drop_zone"
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
    >
      {children}

      {draggedOver && (
        <div className={styles.dropOverlay}>
          <div>Drop card list</div>
        </div>
      )}
    </div>
  )
}
