// title: Magic the Gathering Card List Differ

import React from 'react'

import { AccentColor } from 'utils/accent-color/AccentColorContext'

import { BaseLayout } from 'src/components/layout/Base'
import PageMetaData from 'components/layout/PageMetaData'

import { ListDiffer } from 'components/list-differ/ListDiffer'
import ErrorBoundary from 'components/list-differ/ErrorBoundary'

interface Props {
  pageContext: {
    url: string
  }
}

const ListDifferPage: React.FC = () => {
  return (
    <BaseLayout>
      <AccentColor color="#626465">
        <ErrorBoundary>
          <ListDiffer />
        </ErrorBoundary>
      </AccentColor>
    </BaseLayout>
  )
}

export const Head: React.FC<Props> = (props) => {
  const { pageContext } = props

  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <PageMetaData
        metaData={{
          title: 'Magic: the Gathering Card List Differ',
          description:
            'Compare two decks, Cubes, or any lists of Magic cards to find the shared cards and unique cards in each.',
          themeColor: '#ffffff',
          ogImageURL: 'https://luckypaper.co/og-images/list-differ.png',
        }}
        url={pageContext.url}
        schema={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          name: 'Magic: the Gathering Card List Differ',
          url: props.pageContext.url,
        }}
      />
    </>
  )
}

export default ListDifferPage
