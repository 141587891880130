import { range } from 'lodash'

import { Options } from './options'
import { CardRow } from './types'

export function formatList(list: { cards: CardRow[] }, options: Options) {
  const { oneCardPerRow, showCounts } = options

  return list.cards.flatMap((card) => {
    return oneCardPerRow
      ? range(0, card.count).map(() =>
          showCounts ? `1x ${card.name}` : card.name,
        )
      : [
          showCounts || card.count > 1 || card.partiallyShared
            ? `${card.count}x ${card.name}`
            : card.name,
        ]
  })
}
