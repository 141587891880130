import * as styles from './ResultColumn.module.scss'

import React, { useCallback, useMemo } from 'react'

import { copyToClipboard } from 'src/utils/copyToClipboard'

import * as controls from 'components/controls'
import * as icons from 'components/icons'

import CardHover from 'src/components/cards/CardHover'

import { CardRow } from './data/types'
import { Options } from './data/options'
import { range } from 'lodash'
import { formatText } from './data/formatText'

interface Props {
  heading: string
  colors: string[]
  list: {
    title?: string | null
    cards: CardRow[]
  }
  options: Options
}

export const ResultColumn: React.FC<Props> = (props) => {
  const { heading, colors, list, options } = props
  const { cards } = list
  const { oneCardPerRow, showCounts } = options

  const copyText = useCallback(() => {
    copyToClipboard(formatText(list, options))
  }, [list, options])

  const listCount = useMemo(() => {
    return cards.reduce((total, card) => total + card.count, 0)
  }, [cards])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.heading}>
          {colors.map((color) => (
            <>
              <div
                key={color}
                className={styles.colorIcon}
                style={{ backgroundColor: color }}
              />{' '}
            </>
          ))}
          {heading} - {listCount}
        </div>

        <controls.ControlGroup>
          <controls.CopyButton onClick={copyText} disabled={listCount == 0}>
            Copy
          </controls.CopyButton>

          <controls.SmallButtonLink
            to="/resources/list-formatter/"
            state={{ initialList: formatText(list, options) }}
            disabled={listCount == 0}
          >
            Format <icons.Offsite />
          </controls.SmallButtonLink>
        </controls.ControlGroup>
      </div>

      <div className={styles.cards}>
        {cards.flatMap((card, index) => {
          {
            return oneCardPerRow
              ? range(0, card.count).map((duplicateIndex) => (
                  <div key={`${index}-${duplicateIndex}`}>
                    {showCounts && (
                      <>
                        <span className={styles.count}>1x</span>{' '}
                      </>
                    )}

                    <CardHover card={card.name}>{card.name}</CardHover>
                  </div>
                ))
              : [
                  <div key={index}>
                    {(showCounts || card.count > 1 || card.partiallyShared) && (
                      <>
                        <span className={styles.count}>{card.count}x</span>{' '}
                      </>
                    )}
                    <CardHover card={card.name}>{card.name}</CardHover>
                  </div>,
                ]
          }
        })}
      </div>
    </div>
  )
}
