import { usePersistentState } from 'src/utils/usePersistentState'

import { InputMode } from './types'
import { useCallback, useMemo } from 'react'

export function useInputState() {
  // Modes

  const [leftInputMode, setLeftInputMode] = usePersistentState<InputMode>(
    'list-differ-left-mode',
    'list',
  )

  const [rightInputMode, setRightInputMode] = usePersistentState<InputMode>(
    'list-differ-right-mode',
    'list',
  )

  // State per each mode

  const [leftInputListContent, setLeftInputListContent] = usePersistentState(
    'list-differ-left-list-content',
    '',
  )

  const [leftInputSearchContent, setLeftInputSearchContent] =
    usePersistentState('list-differ-left-search-content', '')

  const [leftInputLinkContent, setLeftInputLinkContent] = usePersistentState(
    'list-differ-left-link-content',
    '',
  )

  const [rightInputListContent, setRightInputListContent] = usePersistentState(
    'list-differ-right-list-content',
    '',
  )

  const [rightInputSearchContent, setRightInputSearchContent] =
    usePersistentState('list-differ-right-search-content', '')

  const [rightInputLinkContent, setRightInputLinkContent] = usePersistentState(
    'list-differ-right-link-content',
    '',
  )

  // Opaque content state

  const leftInputContent = useMemo(() => {
    switch (leftInputMode) {
      case 'list':
        return leftInputListContent
      case 'search':
        return leftInputSearchContent
      case 'link':
        return leftInputLinkContent
    }
  }, [
    leftInputLinkContent,
    leftInputListContent,
    leftInputMode,
    leftInputSearchContent,
  ])

  const setLeftInputContent = useCallback(
    (value: string) => {
      switch (leftInputMode) {
        case 'list':
          setLeftInputListContent(value)
          break
        case 'search':
          setLeftInputSearchContent(value)
          break
        case 'link':
          setLeftInputLinkContent(value)
          break
      }
    },
    [
      leftInputMode,
      setLeftInputLinkContent,
      setLeftInputListContent,
      setLeftInputSearchContent,
    ],
  )

  const rightInputContent = useMemo(() => {
    switch (rightInputMode) {
      case 'list':
        return rightInputListContent
      case 'search':
        return rightInputSearchContent
      case 'link':
        return rightInputLinkContent
    }
  }, [
    rightInputLinkContent,
    rightInputListContent,
    rightInputMode,
    rightInputSearchContent,
  ])

  const setRightInputContent = useCallback(
    (value: string) => {
      switch (rightInputMode) {
        case 'list':
          setRightInputListContent(value)
          break
        case 'search':
          setRightInputSearchContent(value)
          break
        case 'link':
          setRightInputLinkContent(value)
          break
      }
    },
    [
      rightInputMode,
      setRightInputLinkContent,
      setRightInputListContent,
      setRightInputSearchContent,
    ],
  )

  const resetInputState = useCallback(() => {
    setLeftInputMode('list')
    setRightInputMode('list')
    setLeftInputListContent('')
    setLeftInputSearchContent('')
    setLeftInputLinkContent('')
    setRightInputListContent('')
    setRightInputSearchContent('')
    setRightInputLinkContent('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    leftInputMode,
    setLeftInputMode,
    leftInputContent,
    setLeftInputContent,
    rightInputMode,
    setRightInputMode,
    rightInputContent,
    setRightInputContent,
    resetInputState,
  }
}
