import * as styles from './Options.module.scss'

import React from 'react'

import * as controls from 'components/controls'

import { Options as OptionsType } from './data/options'

interface Props {
  options: OptionsType
  setOptions(value: OptionsType): void
}

export const Options: React.FC<Props> = (props) => {
  const { options, setOptions } = props

  return (
    <div className={styles.container}>
      <controls.FormColumn>
        <controls.CheckboxField
          label="Always Show Counts"
          checked={options.showCounts}
          onChange={(event) => {
            setOptions({
              ...options,
              showCounts: event.currentTarget.checked,
            })
          }}
        />

        <controls.Field label="Show Duplicate Cards">
          <controls.Select
            options={[
              {
                name: 'false',
                label: '1x Card Name',
              },
              {
                name: 'true',
                label: 'One Card Per Row',
              },
            ]}
            value={options.oneCardPerRow.toString()}
            onChange={(event) => {
              setOptions({
                ...options,
                oneCardPerRow: event.currentTarget.value === 'true',
              })
            }}
          />
        </controls.Field>
      </controls.FormColumn>
    </div>
  )
}
