import React, { useState } from 'react'

import * as controls from 'components/controls'

import { Help } from './Help'

interface Props {
  reset(): void
}

export const MetaControls: React.FC<Props> = (props) => {
  const { reset } = props

  const [showHelp, setShowHelp] = useState(false)

  return (
    <>
      <controls.ControlGroup>
        <controls.SmallButton onClick={reset} secondary>
          Reset
        </controls.SmallButton>
        <span>-</span>
        <controls.SmallButton
          secondary
          onClick={() => {
            setShowHelp(true)
          }}
        >
          Help & Feedback
        </controls.SmallButton>
      </controls.ControlGroup>

      <controls.ModalWithHeading
        presented={showHelp}
        dismiss={() => setShowHelp(false)}
      >
        <Help />
      </controls.ModalWithHeading>
    </>
  )
}
